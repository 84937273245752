var site = site || {};
site.extole = site.extole || {};
var extole = extole || {};
(function($) {
  site.extole = {
    createZone: function(extoleId) {
      var extoleName = extoleId.replace(extoleId.match('.*extole_zone_'), '');
      extole.createZone({
        name: extoleName,
        element_id: extoleId,
      });
    }
  };
  Drupal.behaviors.extole = {
    attach: function(context) {
      (function(c, b, f, k, a) {
        c[b] = c[b] || {};
        for (c[b].q = c[b].q || []; a < k.length;) f(k[a++], c[b]);
      })(window, 'extole', function(c, b) {
        b[c] = b[c] || function() {
          b.q.push([c, arguments]);
        };
      }, ['createZone'], 0);
      var extoleElement = 'span[id*=extole_zone]';
      $(extoleElement, context).each(function() {
        var $this = $(this);
        var extoleId = $this.attr('id');
        site.extole.createZone(extoleId);
        // To make the whole extole block clickable
        var $extoleBlock = $this.closest('.js-extole-zone');
        if ($extoleBlock.length > 0) {
          $extoleBlock.on('click', function() {
            $this.click();
          }).on('click', extoleElement, function(e) {
            e.stopPropagation();
          });
        }
      });
    }
  };
})(jQuery);
